
import './App.css';
import EighteenthBirthdayCountdown from './Logic';

function App() {
  const daysHrsMinsSecs = {days:152, hours:10, minutes: 0, seconds: 0}
    return(
      <>
        <div class="balloon"></div>
        <div class="balloon"></div>
        <div class="balloon"></div>
        <div class="balloon"></div>
        <div class="balloon"></div>
        <div className="title">countdown to nitya's 18th birthday!</div>
        <div className="App">
            <EighteenthBirthdayCountdown hoursMinSecs={daysHrsMinsSecs}/>
        </div>
        <div className='title'>#FinallyLegal 😍🥳</div>
      </>
    )
}

export default App;