import React from 'react'
import './Logic.css'

const EighteenthBirthdayCountdown = ({hoursMinSecs}) => {
   
    const { days = 0, hours = 0, minutes = 0, seconds = 60 } = hoursMinSecs;
    const [[dys, hrs, mins, secs], setTime] = React.useState([days, hours, minutes, seconds]);
      
    const tick = () => {
    
        if (dys === 0 && hrs === 0 && mins === 0 && secs === 0) 
            reset()
        else if (hrs === 0 && mins === 0 && secs === 0) {
            setTime([dys - 1, 24, 59, 59]);
        }
        else if (mins === 0 && secs === 0) {
            setTime([dys, hrs - 1, 59, 59]);
        } else if (mins === 0) {
            setTime([dys, hrs - 1, 59, 59]);
        }
        else if (secs === 0) {
            setTime([dys, hrs, mins - 1, 59]);
        } else {
            setTime([dys, hrs, mins, secs - 1]);
        }
    };


    const reset = () => setTime([parseInt(hours), parseInt(minutes), parseInt(seconds)]);

    
    React.useEffect(() => {
        const timerId = setInterval(() => tick(), 1000);
        return () => clearInterval(timerId);
    });

    
    return (
        <div className='birthday'>
            <p>{`${hrs.toString() + ' days'} \n ${hrs.toString() + ' hours'} ${mins
            .toString() + ' minutes'} \n ${secs.toString() + ' seconds'}`}</p> 
        </div>
    );
}

export default EighteenthBirthdayCountdown;